/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PaymentVerificationPojo { 
    paymentStatus?: PaymentVerificationPojo.PaymentStatusEnum;
    organizationName?: string;
    servicePaidFor?: string;
    expiryDate?: string;
    planName?: string;
}
export namespace PaymentVerificationPojo {
    export type PaymentStatusEnum = 'ACTIVE' | 'REVERSED' | 'DEACTIVATED';
    export const PaymentStatusEnum = {
        ACTIVE: 'ACTIVE' as PaymentStatusEnum,
        REVERSED: 'REVERSED' as PaymentStatusEnum,
        DEACTIVATED: 'DEACTIVATED' as PaymentStatusEnum
    };
}


