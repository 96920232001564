<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Billing
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 p-0">
        <li class="breadcrumb-item d-inline-block position-relative">
            <a [routerLink]="'/dashboard'" class="d-inline-block position-relative">
                <i class="material-symbols-outlined">
                    home
                </i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            Billing
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            Bill Details
        </li>
    </ol>
</div>

<!-- Invoice Details -->
<div class="invoice-details-card trezo-card mb-25 border-radius d-block bg-white border-0 shadow-none">
    <div class="trezo-card-content">
        <div class="invoice-info d-md-flex align-items-center justify-content-between">
            <div>
                <span class="title d-block">
                    Invoice Number: <span class="text-black">{{ bill.billNumber }}</span>
                </span>
                <span class="d-block">
                    Invoice To:
                </span>
                <span class="d-block text-black">
                    {{ bill.customerName }}
                </span>

                <span class="d-block text-black">
                    {{ bill.customerAddress }}
                </span>
                <span class="d-block text-black">
                    {{ bill.customerPhoneNumber}}
                </span>
            </div>
            <div>
                <img src="images/qr-code.svg" class="qr-code-image" alt="qr-code-image">
            </div>
            <div>
                <span class="title d-block text-body">
                    Date: <span class="text-black">{{ bill.createdAt | date }}</span>
                </span>
                <span class="d-block text-body">
                    Pay To:
                </span>
                <span class="d-block">
                    {{ bill.billerName }}
                </span>
                <span class="d-block">
                    {{ bill.billerEmail}}
                </span>
                <span class="d-block">
                   {{ bill.billerPhoneNumber }}
                </span>
            </div>
        </div>
        <div class="basic-table table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col" class="fw-medium">
                        S/N
                    </th>
                    <th scope="col" class="fw-medium">
                        Ticket Type
                    </th>
                    <th scope="col" class="fw-medium">
                        Quantity
                    </th>
                    <th scope="col" class="fw-medium">
                        Unit Price
                    </th>
                    <th scope="col" class="fw-medium">
                        Total
                    </th>
                </tr>
                </thead>
                <tbody>
                @for ( item of bill.billItems; track item; let i = $index) {
                    <tr>
                        <td class="text-body">
                            {{ i + 1}}
                        </td>
                        <td class="fw-medium">
                            {{ item.description }}
                        </td>
                        <td class="text-body">
                            {{ item.quantity }}
                        </td>
                        <td class="text-body">
                            {{ item.unitAmount | currency : '₦' }}
                        </td>
                        <td class="text-body">
                            {{ item.amount | currency : '₦' }}
                        </td>
                    </tr>
                }
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-black fw-medium">
                        Total
                    </td>
                    <td class="text-black">
                        {{ bill.amount | currency : '₦' }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="form-btn-box text-center">
            <button type="button" class="primary d-inline-block fw-medium border-radius border-0 transition">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        download
                    </i>
                    Download
                </span>
            </button>
            <button (click)="initiateSquadPayment(bill)" type="button" class="success d-inline-block fw-medium border-radius border-0 transition">
                Pay
            </button>
        </div>
    </div>
</div>
