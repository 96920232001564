<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Tickets Sale
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 p-0">
        <li class="breadcrumb-item d-inline-block position-relative">
            <a routerLink="/dashboard" class="d-inline-block position-relative">
                <i class="material-symbols-outlined">
                    home
                </i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            Tickets
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            Ticket Sale List
        </li>
    </ol>
</div>

<!-- To Do List -->
<!-- To Do List -->
<div
        class="trezo-card table-style-two mb-25 border-radius d-block bg-white border-0 shadow-none"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="trezo-card-header d-sm-flex align-items-center justify-content-end">
        <div class="trezo-card-subtitle">
            <button class="add-btn d-inline-block transition fw-medium border-radius" type="button" (click)="createTicketSale()">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        add
                    </i>
                    Sell Ticket
                </span>
            </button>
        </div>
    </div>
    <div class="trezo-card-header d-sm-flex align-items-center justify-content-between mt-4">
        <div class="trezo-card-title">
            <form [formGroup]="form" class="search-box position-relative">
                <label class="text-black">
                    <i class="material-symbols-outlined">
                        search
                    </i>
                </label>
                <input type="text" class="input-search d-block w-100 border-radius" placeholder="Payment Reference...." [formControlName]="'paymentReference'">
            </form>
        </div>
        <div class="trezo-card-subtitle">
            <button class="add-btn btn-primary d-inline-block transition fw-medium border-radius" type="button" (click)="submit()">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        search
                    </i>
                    Search
                </span>
            </button>
        </div>
    </div>
    <div class="trezo-card-content">
        <div class="basic-table table-responsive">
            <table class="table mb-0">
                <thead>
                <tr>
                    <th scope="col" class="fw-medium">S/N</th>
                    <th scope="col" class="fw-medium">Date of Sales</th>
                    <th scope="col" class="fw-medium">Agent Name</th>
                    <th scope="col" class="fw-medium">Ticket Type</th>
                    <th scope="col" class="fw-medium">Zone</th>
                    <th scope="col" class="fw-medium">Batch Number</th>
                    <th scope="col" class="fw-medium">Amount (N)</th>
                    <th scope="col" class="fw-medium">Payment Reference</th>
                    <th scope="col" class="fw-medium">Assigned To</th>
                </tr>
                </thead>
                <tbody>
                    @for (d of searchManager.list; track d; let i = $index) {
                        <tr>
                            <td class="text-body">{{ i + 1 }}</td>
                            <td>{{d.dateOfSales | date}}</td>
                            <td>{{d.agentName}}</td>
                            <td>{{d.ticketType}}</td>
                            <td>{{d.zone}}</td>
                            <td>{{d.batchNumber}}</td>
                            <td>{{d.amount }}</td>
                            <td>{{d.paymentReference}}</td>
                            <td>{{d.assignedTo}}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
</div>
