/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BillItemPojo } from './bill-item-pojo';
import { InvoicePojo } from './invoice-pojo';
import { BinaryData } from './binary-data';
import { PortalUserPojo } from './portal-user-pojo';


export interface BillPojo { 
    createdAt?: string;
    amount?: number;
    status?: BillPojo.StatusEnum;
    customerEmail?: string;
    customerName?: string;
    customerPhoneNumber?: string;
    datePaid?: string;
    billNumber?: string;
    paymentStatus?: BillPojo.PaymentStatusEnum;
    createdBy?: PortalUserPojo;
    approvalStatus?: BillPojo.ApprovalStatusEnum;
    billType?: BillPojo.BillTypeEnum;
    qrcode?: BinaryData;
    customerAddress?: string;
    description?: string;
    dueDate?: string;
    reasonForDisapproval?: string;
    paymentInvoice?: InvoicePojo;
    billItems?: Array<BillItemPojo>;
    serviceRequestType?: string;
    billerName?: string;
    billerPhoneNumber?: string;
    billerEmail?: string;
    amountPaid?: number;
}
export namespace BillPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type PaymentStatusEnum = 'PAID' | 'PENDING' | 'PARTIAL';
    export const PaymentStatusEnum = {
        PAID: 'PAID' as PaymentStatusEnum,
        PENDING: 'PENDING' as PaymentStatusEnum,
        PARTIAL: 'PARTIAL' as PaymentStatusEnum
    };
    export type ApprovalStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ApprovalStatusEnum = {
        APPROVED: 'APPROVED' as ApprovalStatusEnum,
        PENDING: 'PENDING' as ApprovalStatusEnum,
        DISAPPROVED: 'DISAPPROVED' as ApprovalStatusEnum
    };
    export type BillTypeEnum = 'TICKET_BATCH';
    export const BillTypeEnum = {
        TICKET_BATCH: 'TICKET_BATCH' as BillTypeEnum
    };
}


