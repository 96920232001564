// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: '/api',
  siteUrl: 'https://tickets.serverna.com.ng/api',
  brandName: 'SmartTickets',
  keycloakConfig: {
    clientId: 'tickets-frontend',
    realm: 'tickets',
    url: 'https://auth.serverna.com.ng/auth',
  },
  sessionTimeout: 1440,
  defaultCountry: 'NGA',
  recaptcha: {
    siteKey: '6LcaD7soAAAAACRHrPdsCM8BtR3Ax7GW5v7_sWNt'
  },
  dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
  dateFormat: 'YYYY-MM-DD',
  reportUrl: 'http://localhost:8083/report',
  squadPublicKey: 'sandbox_pk_77b6dd535a4029f55a91548cea0219e71748df4e2e51',
};
