import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {BillControllerService, BillPojo, PaymentVerificationPojo} from "../../../../../sdk/tickets-sdk";
import {environment} from "../../../environments/environment";
import {CurrencyPipe, DatePipe, Location} from "@angular/common";
import {PaymentControllerService} from "../../../../../sdk/tickets-sdk";
import {switchMap} from "rxjs/operators";

@Component({
    selector: 'app-bill-details',
    standalone: true,
    imports: [
        RouterLink,
        DatePipe,
        CurrencyPipe
    ],
    templateUrl: './bill-details.component.html',
    styleUrl: './bill-details.component.scss'
})
export class BillDetailsComponent implements OnInit {
    bill: BillPojo;
    private defaultTicketsInBatch: number = 100;
    billDownloadUrl: string;
    private checkingPayment: boolean;

    constructor(
        private location: Location,
        private activatedRoute: ActivatedRoute,
        private paymentController: PaymentControllerService,
        private billControllerService: BillControllerService,
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe(params => {
            if (params['invoiceNumber']) {
                const invoiceNumber = params['invoiceNumber'];
                this.getBill(invoiceNumber);
            }
        });
    }


    getBill(invoiceNumber): void {
        this.billControllerService.getBill({invoiceNumber}).subscribe({
            next: (bill: BillPojo) => {
                this.bill = bill;
            },
            error: (error) => {
                this.location.back();
                console.error("An error occurred while fetching bill details.");
            }
        });
    }

    getAmountPerRow(unitAmount: number, quantity: number): number {
        return (unitAmount * this.defaultTicketsInBatch) * quantity;
    }

    initiateSquadPayment(bill: BillPojo): void {

        if ((window as any).squad) {
            const squadInstance = new (window as any).squad({

                onLoad: (v) => console.log('Widget loaded successfully ===>', v),

                onClose: (v) => {
                    console.log('Widget closed ===>', v);
                    this.processTransaction(bill);
                },

                onSuccess: (v) => {
                    console.log('Payment successful ===>', v);
                    this.processTransaction(bill);
                },

                key: environment.squadPublicKey,
                email: bill.customerEmail,
                amount: bill.amount * 100,
                currency_code: 'NGN',
                transaction_ref: bill.paymentInvoice.invoiceNumber
            });

            squadInstance.setup();
            squadInstance.open();
        } else {
            console.error("Squad widget not found");
        }
    }



    private processTransaction(bill: BillPojo) {
        this.paymentController.verifyPaymentStatus({transactionRef: bill.paymentInvoice.invoiceNumber, provider: 'SQUAD'}).subscribe({
            next: (paymentVerification: PaymentVerificationPojo) => {
                this.getBill(bill.billNumber);
            },
            error: () => {
                console.error("An error occurred while verifying payment status.");
            }
        });
    }
}
