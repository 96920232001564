import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {
  AgentPojo,
  DropDownListControllerService,
  Organization, OrganizationSummaryPojo,
  SearchOrganizationsRequestParams,
  SearchTicketSalesRequestParams,
  TicketSalesControllerService, TicketSalesPojo, TicketType,
  Zone
} from "../../../../../sdk/tickets-sdk";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {BsModalService} from "ngx-bootstrap/modal";
import {AuthenticationService} from "../../../services/authentication.service";
import {PageManager} from "../../../services/page-manager";
import {SearchManager} from "../../../shared/search/search-manager";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {PaginatedSearch} from "../../../shared/search/paginated-search";
import {Observable} from "rxjs";
import {SearchHandler} from "../../../shared/search/search-handler";
import {SearchFilterSource} from "../../../shared/search/search-filter-source";
import {User} from "../../../models/user/user.model";
import {DatePipe, Location, TitleCasePipe} from "@angular/common";
import moment from "moment";
import {RemoveUnderscorePipe} from "../../../shared/pipes/remove-underscore.pipe";
import {CustomizerSettingsService} from "../../../customizer-settings/customizer-settings.service";

@Component({
  selector: 'app-tickets-list',
  standalone: true,
  imports: [
    DatePipe,
    ReactiveFormsModule,
    RemoveUnderscorePipe,
    RouterLink,
    TitleCasePipe
  ],
  providers: [BsModalService],
  templateUrl: './tickets-list.component.html',
  styleUrl: './tickets-list.component.scss'
})
export class TicketsListComponent implements OnInit, SearchHandler<TicketSalesPojo, SearchTicketSalesRequestParams>,
    SearchFilterSource<SearchTicketSalesRequestParams> {

  form!: FormGroup;
  fetchingZones = false;
  zones: Array<Zone>;
  fetchingTicketTypes = false;
  ticketTypes: Array<TicketType> | undefined;
  fetchingAgents = false;
  agents: Array<AgentPojo> | undefined;
  searchManager!: SearchManager<TicketSalesPojo, SearchTicketSalesRequestParams>
  maxPageSize = 10;
  user: User | undefined;
  organization: Organization;

  private dateValid: boolean = true;


  minDate = new Date(2000, 1, 1);

  constructor(private fb: FormBuilder,
              protected themeService: CustomizerSettingsService,
              private activatedRoute: ActivatedRoute,
              private toaster: ToastrService,
              private location: Location,
              private bsModalService: BsModalService,
              private dropdownListConstroller: DropDownListControllerService,
              private ticketSalesControllerService: TicketSalesControllerService,
              private authenticationService: AuthenticationService,
              private pageManager: PageManager,) {
    this.searchManager = new SearchManager<TicketSalesPojo, SearchTicketSalesRequestParams>(this, this);

  }

  ngOnInit(): void {
    this.form = this.fb.group({
      paymentReference: [''],
      batchNumber: [''],
      ticketType: [''],
      salesFromDate: [null],
      salesToDate: [null],
      agent: [''],
      zone: [''],
    });
    this.authenticationService.getUser().subscribe(value => {
      this.user = value;
    });

    this.authenticationService.getAccount().subscribe(v => {
      if (!v.organization) {
        this.location.back();
        return;
      }
      this.organization = v.organization;
      this.getTicketTypes(this.organization.id);
      this.getZones(this.organization.id);
      this.getAgents(this.organization.id);
    })

    this.activatedRoute.queryParams.subscribe(params => {
      this.form.patchValue({...params});
      this.searchManager.reloadAndShowFirstPage();
    });

    this.form.get('salesFromDate')?.valueChanges.subscribe(v => {
      this.minDate = v;
      if (this.form.get('salesToDate').value) {
        if (moment(v) > moment(this.form.get('salesToDate').value)) {
          this.toaster.error("Invalid Date Range Selected");
          this.dateValid = false;
        } else {
          this.dateValid = true;
        }
      }
    });

    this.form.get('salesToDate')?.valueChanges.subscribe(v => {
      if (this.form.get('salesFromDate').value) {
        if (moment(v) < moment(this.form.get('salesFromDate').value)) {
          this.toaster.error("Invalid Date Range Selected");
          this.dateValid = false;
        } else {
          this.dateValid = true;
        }
      }
    });


  }


  getMinDate() {
    return this.minDate;
  }

  getTicketTypes(id: number) {
    this.fetchingTicketTypes = true;
    this.dropdownListConstroller.getTicketTypes({orgId: id})
        .subscribe(value => {
          this.ticketTypes = value.results;

          this.fetchingTicketTypes = false;
        });

  }

  getPersistentKey(): string {
    return TicketsListComponent.name;
  }

  getZones(id: number) {
    this.fetchingZones = true;
    this.dropdownListConstroller.getZones({orgId: id})
        .subscribe(value => {
          this.zones = value.results;
          this.fetchingZones = false;
        });

  }

  getAgents(id: number) {
    this.fetchingAgents = true;
    this.dropdownListConstroller.getAgents({orgId: id})
        .subscribe(value => {
          console.log(value);
          this.agents = value.results;
          this.fetchingAgents = false;
        });

  }

  getFilter(): SearchTicketSalesRequestParams {
    let d: SearchTicketSalesRequestParams = {
      ...this.form.value
    }

    if (d.salesFromDate) {
      console.log(d.salesFromDate);
      d.salesFromDate = `${moment(d.salesFromDate).format("YYYY-MM-DD")}`;
    }

    if (d.salesToDate) {
      d.salesToDate = `${moment(d.salesToDate).add({day: 0}).format("YYYY-MM-DD")}`;
    }
    return d;
  }

  now() {
    return new Date();
  }


  getSearchDescriptor(e: SearchTicketSalesRequestParams): NameValuePair[] {
    const descriptions: NameValuePair[] = [];

    Object.keys(e).forEach((key) => {
      if (!(e as any)[key]) {
        return;
      }
      descriptions.push(new NameValuePair(`${(e as any)[key]}`, key));
    });
    return descriptions;
  }


  submit() {
    console.log(this.form.value);
    // return;
    if (!this.form.valid) {
      return;
    }

    if (this.form.get('salesFromDate').value || this.form.get('salesToDate').value) {
      if (!this.dateValid) {
        this.toaster.error('Invalid Date Range Selected');
        return;
      }
    }


    this.updateSearchQuery();
    this.searchManager.reloadAndShowFirstPage();
  }


  search(page: number | undefined, filter?: SearchOrganizationsRequestParams): Observable<OrganizationSummaryPojo | any> {
    const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
    let paginatedSearch = new PaginatedSearch(filter, this.searchManager.itemsPerPage, offset);
    return this.ticketSalesControllerService.searchTicketSales({...paginatedSearch.getSearchParams()});
  }

  updateSearchQuery() {
    this.pageManager.storeData(TicketsListComponent.name, 'search', this.form.value);
  }

  private loadSearchData() {
    this.pageManager.getData(TicketsListComponent.name, 'search');
  }


  createTicketSale() {
    /*let bsModalRef = this.bsModalService.show(CreateTicketSalesComponent, {
      initialState: {
        organizationId: this.organization.id,
        ticketTypes: this.ticketTypes,
      },
      class: 'modal-lg'
    });
    bsModalRef.content.onSellTicketCreated.subscribe(v => {
      this.searchManager.reloadAndShowFirstPage();
    })*/
  }
}
