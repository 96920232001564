/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RequestOtpDto { 
    requesterPhoneNumber?: string;
    requesterEmail?: string;
    deliveryMode: RequestOtpDto.DeliveryModeEnum;
    type: RequestOtpDto.TypeEnum;
}
export namespace RequestOtpDto {
    export type DeliveryModeEnum = 'SMS' | 'EMAIL' | 'PHONE_CALL';
    export const DeliveryModeEnum = {
        SMS: 'SMS' as DeliveryModeEnum,
        EMAIL: 'EMAIL' as DeliveryModeEnum,
        PHONE_CALL: 'PHONE_CALL' as DeliveryModeEnum
    };
    export type TypeEnum = 'USER_REGISTRATION' | 'PASSWORD_RESET' | 'PHONE_NUMBER_UPDATE' | 'GENERAL';
    export const TypeEnum = {
        USER_REGISTRATION: 'USER_REGISTRATION' as TypeEnum,
        PASSWORD_RESET: 'PASSWORD_RESET' as TypeEnum,
        PHONE_NUMBER_UPDATE: 'PHONE_NUMBER_UPDATE' as TypeEnum,
        GENERAL: 'GENERAL' as TypeEnum
    };
}


