import {Component, OnInit} from '@angular/core';
import { Router, RouterLink } from "@angular/router";
import {SearchManager} from "../../../shared/search/search-manager";
import {HttpErrorResponse} from "@angular/common/http";
import {
  AgentPojo, Bill,
  BillControllerService,
  BillPojo, DropDownListControllerService,
  Organization, OrganizationControllerService, OrganizationSummaryPojo,
  SearchBillsRequestParams
} from "../../../../../sdk/tickets-sdk";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {PaginatedSearch} from "../../../shared/search/paginated-search";
import {Observable} from "rxjs";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {User} from "../../../models/user/user.model";
import {SearchFilterSource} from "../../../shared/search/search-filter-source";
import {AuthenticationService} from "../../../services/authentication.service";
import {PageManager} from "../../../services/page-manager";
import {BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import moment from "moment";
import {BillCreationModalComponent} from "../bill-creation-modal/bill-creation-modal.component";
import {CustomizerSettingsService} from "../../../customizer-settings/customizer-settings.service";
import {CurrencyPipe, DatePipe, NgClass, TitleCasePipe} from "@angular/common";
import PaymentStatusEnum = Bill.PaymentStatusEnum;

@Component({
  selector: 'app-bill-list',
  standalone: true,
  imports: [
    FormsModule,
    RouterLink,
    ReactiveFormsModule,
    CurrencyPipe,
    DatePipe,
    TitleCasePipe,
    NgClass
  ],
  providers: [BsModalService],
  templateUrl: './bill-list.component.html',
  styleUrl: './bill-list.component.scss'
})
export class BillListComponent implements OnInit, SearchFilterSource <SearchBillsRequestParams> {

  form!: FormGroup;
  countryId: any;
  searchManager!: SearchManager<BillPojo, SearchBillsRequestParams>
  user: User |undefined;
  isPortalAdmin: boolean;
  isOrganizationAdmin: boolean;
  isAgent: boolean;
  fetchingAgent: boolean;
  fetchingOrganization: boolean;
  organizations: Array<Organization>;
  organizationId: number;
  agent: Array<AgentPojo> = [];
  private minDate: any;
  agentId: number;

  constructor(
      private fb: FormBuilder,
      private authenticationService: AuthenticationService,
      private pageManager: PageManager,
      private billControllerService: BillControllerService,
      protected themeService: CustomizerSettingsService,
      private bsModalService: BsModalService,
      private dropDownListControllerService: DropDownListControllerService,
      private organizationControllerService: OrganizationControllerService,
      private toastrService: ToastrService,
      private route: Router
  ) {
    this.searchManager = new SearchManager<BillPojo, SearchBillsRequestParams>(this, this);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      paymentStatus: [''],
      zoneCode: [''],
      organizationId: [''],
      createdAfter: [''],
      createdBefore: [''],
      agentId: [''],
      billNumber: [''],
      ticketType: [''],
      batchNumber: [''],
    });
    this.authenticationService.getUser().subscribe(value => {
      this.user=value;
      this.loadSearchData();
    });
    this.authenticationService.getAccount().subscribe(account => {
      if (account.accountType == 'ADMIN') {
        this.isPortalAdmin = true;
        this.isOrganizationAdmin = false;
        this.isAgent = false;
        this.fetchOrganization();
        // this.loadSearchData();

      } else if (account.accountType == 'ORGANIZATION' && account.roles.includes('ADMIN')) {
        this.isPortalAdmin = false;
        this.isOrganizationAdmin = true;
        this.isAgent = false;
        this.organizationId = account.organization.id
        this.form.controls.organizationId.disable();
        this.fetchAgent()
        // this.loadSearchData();
      } else if (account.accountType == 'ORGANIZATION' && account.roles.includes('AGENT')) {
        this.isPortalAdmin = false;
        this.isOrganizationAdmin = false;
        this.isAgent = true;
        this.organizationId = account.organization.id
        this.agentId = account?.agent.id;
        this.form.controls.organizationId.disable();
        this.form.controls.agentId.disable();
      }
    })

    this.form.controls.organizationId.valueChanges.subscribe( v => {
      if (v) {
        this.organizationId = v;
        this.fetchAgent()
      }
    });

    this.form.get('createdAfter')?.valueChanges.subscribe(v => {
      this.minDate = v;
      if (this.form.get('createdBefore').value) {

        if (moment(v) > moment(this.form.get('createdBefore').value)) {
          this.toastrService.error("Invalid Date Range Selected");
        } else {
        }
      }
    });
    this.form.patchValue(this.searchManager.getSavedFilterData());
  }

  fetchOrganization() {
    this.fetchingOrganization = true
    this.organizationControllerService.searchOrganizations({limit: 1000}).subscribe( v => {
      this.fetchingOrganization = false;
      this.organizations = v.results;
    }, (error: HttpErrorResponse) => {
      this.fetchingOrganization = false;
      this.toastrService.error(error.message ? error.message : 'Error fetching Organisation')
    });
  }


  generateBill() {
    const bsModalRef = this.bsModalService.show(BillCreationModalComponent, {
      initialState: {
      },
      class: 'modal-lg'
    })

    bsModalRef.content.onConfirmClicked.subscribe( v => {
      this.viewBill(v);
    })
  }

  viewBill(invoiceNumber: string) {
    this.route.navigate(['/bills/detail', invoiceNumber])
  }

  fetchAgent() {
    this.fetchingAgent = true;
    this.dropDownListControllerService.getAgents({orgId: this.organizationId}).subscribe( v => {
      this.fetchingAgent = false;
      this.agent = v.results;
    }, (error: HttpErrorResponse) => {
      this.fetchingAgent = false;
    })
  }

  getFilter(): SearchBillsRequestParams {
    return {
      ...this.form.value
    };
  }

  getPersistentKey(): string {
    return BillListComponent.name+this.user?.userId;
  }

  getSearchDescriptor(e: SearchBillsRequestParams): NameValuePair[] {
    const descriptions: NameValuePair[] = [];

    Object.keys(e).forEach((key) => {
      if (!(e as any)[key]) {
        return;
      }
      let val = (e as any)[key];
      if (key == 'organizationId' && this.isPortalAdmin) {
        key = 'organization';
        val = this.organizations.find( o => o.id == val).name;
      }
      if (key == 'agentId' && this.agent.length > 0 && (this.isPortalAdmin || this.isOrganizationAdmin)) {
        val = this.agent.find( o => o.id == val).name;
        key = 'agent';
      }
      if (key == 'createdBefore') {
        val = moment(val).format('DD-MM-YYYY');
      }
      if (key == 'createdAfter') {
        val = moment(val).format('DD-MM-YYYY');
      }
      descriptions.push(new NameValuePair(val, key));
    });
    return descriptions;
  }

  submit() {
    if (!this.form.valid) {
      return;
    }
    this.updateSearchQuery();
    this.searchManager.reloadAndShowFirstPage();
  }

  search(page: number | undefined, filter?: SearchBillsRequestParams): Observable<AgentPojo | any> {
    const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;
    if (this.isOrganizationAdmin) {
      filter.organizationId = this.organizationId;
    } else if (this.isAgent) {
      filter.organizationId = this.organizationId;
      filter.agentId = this.agentId;
    }
    let paginatedSearch = PaginatedSearch.createCleanFilter(filter, this.searchManager.itemsPerPage, offset);
    return this.billControllerService.searchBills({...paginatedSearch.getSearchParams()});
  }

  updateSearchQuery() {
    this.pageManager.storeData(BillListComponent.name, 'search', this.form.value);
  }

  private loadSearchData() {
    let searchData = this.searchManager.getSavedFilterData();
    if (searchData?.createdAfter) {
      searchData.createdAfter = moment(searchData.createdAfter).toDate() as any;
    }
    if (searchData?.createdBefore) {
      searchData.createdBefore = moment(searchData.createdBefore).toDate() as any;
    }
    this.form.patchValue(searchData);
  }

  now() {
    return new Date();
  }

  getMinDate() {
    return this.minDate
  }



  getStatusStyle(approvalStatus: BillPojo.PaymentStatusEnum) {
    switch (approvalStatus) {
      case PaymentStatusEnum.PAID:
        return 'pFinished';
      case PaymentStatusEnum.PENDING:
        return 'pInProgress';
      case PaymentStatusEnum.PARTIAL:
        return 'rejected';
      default:
        return '';
    }
  }
}
