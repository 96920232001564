import {Component, OnInit} from '@angular/core';
import {DatePipe, NgClass, TitleCasePipe} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {RemoveUnderscorePipe} from "../../../shared/pipes/remove-underscore.pipe";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {CustomizerSettingsService} from "../../../customizer-settings/customizer-settings.service";
import {
    AgentControllerService,
    AgentPojo,
    Country,
    DropDownListControllerService,
    Lga,
    Organization,
    OrganizationControllerService,
    OrganizationWithMembershipStatus,
    SearchAgentRequestParams,
    SearchOrganizationsRequestParams,
    State,
    Zone
} from "../../../../../sdk/tickets-sdk";
import {ToastrService} from "ngx-toastr";
import {CustomValidators} from "../../../shared/validators/custom-validators";
import {HttpErrorResponse} from "@angular/common/http";
import {ConfirmDialogComponent} from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import {NameValuePair} from "../../../models/etc/name-value-pair.model";
import {PaginatedSearch} from "../../../shared/search/paginated-search";
import {Observable} from "rxjs";
import {SearchFilterSource} from "../../../shared/search/search-filter-source";
import {SearchManager} from "../../../shared/search/search-manager";
import {User} from "../../../models/user/user.model";
import {AuthenticationService} from "../../../services/authentication.service";
import {PageManager} from "../../../services/page-manager";
import {BsModalService} from "ngx-bootstrap/modal";
import {SearchHandler} from "../../../shared/search/search-handler";
import moment from "moment";
import {AgentCreationModalComponent} from "../agent-creation-modal/agent-creation-modal.component";
import {EditAgentModalComponent} from "../edit-agent-modal/edit-agent-modal.component";
import StatusEnum = AgentPojo.StatusEnum;

@Component({
    selector: 'app-agent-list',
    standalone: true,
    imports: [
        DatePipe,
        ReactiveFormsModule,
        RemoveUnderscorePipe,
        RouterLink,
        TitleCasePipe,
        NgClass
    ],
    providers: [BsModalService],
    templateUrl: './agent-list.component.html',
    styleUrl: './agent-list.component.scss'
})
export class AgentListComponent implements OnInit, SearchHandler<AgentPojo, SearchAgentRequestParams>,
    SearchFilterSource <SearchOrganizationsRequestParams> {
    form!: FormGroup;
    countries: Array<Country> | undefined;
    countryId: any;
    stateId: any;
    fetchingStates = false;
    states: Array<State> | undefined;
    fetchingLga = false;
    lgas: Array<Lga> | undefined;
    searchManager!: SearchManager<AgentPojo, SearchAgentRequestParams>
    maxPageSize = 10;
    user: User | undefined;
    isPortalAdmin: boolean;
    fetchingZone: boolean;
    fetchingOrganization: boolean;
    organizations: Array<Organization>;
    organizationId: number;
    zones: Array<Zone> = [];
    private minDate: any;

    constructor(
        private fb: FormBuilder,
        protected themeService: CustomizerSettingsService,
        private authenticationService: AuthenticationService,
        private pageManager: PageManager,
        private agentControllerService: AgentControllerService,
        private activatedRoute: ActivatedRoute,
        private bsModalService: BsModalService,
        private dropDownListControllerService: DropDownListControllerService,
        private organizationControllerService: OrganizationControllerService,
        private toastrService: ToastrService,
    ) {
        this.searchManager = new SearchManager<AgentPojo, SearchAgentRequestParams>(this, this);
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            name: [''],
            phoneNumber: ['', [CustomValidators.nigerianNumber]],
            status: [''],
            zoneCode: [''],
            organizationId: [''],
            createdAfter: [''],
            createdBefore: [''],
            organizationName: ['']
        });

        // TODO: Add null/undefined checks
        this.authenticationService.getUser().subscribe(value => {
            this.user = value;
        });
        this.authenticationService.getAccount().subscribe(account => {
            if (account.accountType == 'ADMIN') {
                this.isPortalAdmin = true;
                this.fetchOrganization();

            } else {
                this.isPortalAdmin = false;
                this.organizationId = account.organization.id
                this.form.controls.organizationId.disable();
                this.fetchZones()
            }
        })

        this.form.controls.organizationId.valueChanges.subscribe(v => {
            if (v) {
                this.organizationId = v;
                this.fetchZones()
            }
        });

        this.form.get('createdAfter')?.valueChanges.subscribe(v => {
            this.minDate = v;
            if (this.form.get('createdBefore').value) {
                if (moment(v) > moment(this.form.get('createdBefore').value)) {
                    this.toastrService.error("Invalid Date Range Selected");
                } else {
                }
            }
        });
    }

    fetchOrganization() {
        this.fetchingOrganization = true
        this.organizationControllerService.searchOrganizations({limit: 1000}).subscribe(v => {
            this.fetchingOrganization = false;
            this.organizations = v.results;
        }, (error: HttpErrorResponse) => {
            this.fetchingOrganization = false;
            this.toastrService.error(error.message ? error.message : 'Error fetching Organisation')
        });
    }

    getOrganizationNames(orgs: Array<OrganizationWithMembershipStatus> | []) {
        return orgs.map(o => o.name).join(', ');
    }

    createAgent() {
        const bsModalRef = this.bsModalService.show(AgentCreationModalComponent, {
            initialState: {},
            class: 'modal-lg'
        })

        bsModalRef.content.onConfirmClicked.subscribe(v => {
            this.searchManager.reloadAndShowFirstPage();
        })
    }

    editAgent(agentId: number) {
        const bsModalRef = this.bsModalService.show(EditAgentModalComponent, {
            initialState: {
                agentId
            },
            class: 'modal-lg'
        })

        bsModalRef.content.onConfirmClicked.subscribe(v => {
            this.searchManager.reloadAndShowFirstPage();
        })
    }

    deactivateAgent(id: number) {
        let bsModalRef = this.bsModalService.show(ConfirmDialogComponent, {
            initialState: {
                dismissOnConfirm: false,
                body: 'Are you sure you want to deactivate this agent?',
                positiveButtonText: 'Deactivate'
            }
        });
        bsModalRef?.content?.onConfirmClicked.subscribe(v => {
            this.agentControllerService.deactivateAgent({id, orgId: this.organizationId})
                .subscribe(value => {
                    bsModalRef.hide();
                    this.searchManager.reloadAndShowFirstPage();
                }, error => {
                    bsModalRef.content.loading = false;
                    if (error.error && typeof error.error == 'object' && error.error.message) {
                        this.toastrService.error(error.error.message);
                        return;
                    }
                    this.toastrService.error('Could not deactivated this agent')
                });
        });

    }

    reactivateAgent(id: number) {
        let bsModalRef = this.bsModalService.show(ConfirmDialogComponent, {
            initialState: {
                dismissOnConfirm: false,
                body: 'Are you sure you want to reactivate this agent?',
                positiveButtonText: 'Reactivate'
            }
        });
        bsModalRef?.content?.onConfirmClicked.subscribe(v => {
            this.agentControllerService.reactivateAgent({id, reactivateAgentDto: {organizationId: this.organizationId}})
                .subscribe(value => {
                    bsModalRef.hide();
                    this.searchManager.reloadAndShowFirstPage();
                }, error => {
                    bsModalRef.content.loading = false;
                    if (error.error && typeof error.error == 'object' && error.error.message) {
                        this.toastrService.error(error.error.message);
                        return;
                    }
                    this.toastrService.error('Could not reactivated this agent')
                });
        });

    }

    fetchZones() {
        this.fetchingZone = true;
        this.dropDownListControllerService.getZones({orgId: this.organizationId}).subscribe(v => {
            this.fetchingZone = false;
            this.zones = v.results;
        }, (error: HttpErrorResponse) => {
            this.fetchingZone = false;
            // this.toastrService.error(error.message ? error.message : 'Error fetching Zones')
        })
    }

    getFilter(): SearchOrganizationsRequestParams {
        return {
            ...this.form.value
        };
    }

    getPersistentKey(): string {
        return AgentListComponent.name + this.user?.userId;
    }

    getSearchDescriptor(e: SearchOrganizationsRequestParams): NameValuePair[] {
        const descriptions: NameValuePair[] = [];

        Object.keys(e).forEach((key) => {
            if (!(e as any)[key]) {
                return;
            }
            let val = (e as any)[key];
            if (key == 'organizationId') {
                key = 'organization';
                val = this.organizations.find(o => o.id == val).name;
            }
            if (key == 'zoneCode' && this.zones.length > 0) {
                val = this.zones.find(o => o.code == val).name;
                key = 'zone';
            }
            descriptions.push(new NameValuePair(val, key));
        });
        return descriptions;
    }

    submit() {
        if (!this.form.valid) {
            return;
        }
        this.updateSearchQuery();
        this.searchManager.reloadAndShowFirstPage();
    }

    search(page: number | undefined, filter?: SearchAgentRequestParams): Observable<AgentPojo | any> {
        const offset = ((page || 0) - 1) * this.searchManager.itemsPerPage;

        if (this.isPortalAdmin) {
            filter.status = 'ACTIVE';
        }

        let paginatedSearch = PaginatedSearch.createCleanFilter(filter, this.searchManager.itemsPerPage, offset);
        return this.agentControllerService.searchAgent({...paginatedSearch.getSearchParams()});
    }

    updateSearchQuery() {
        this.pageManager.storeData(AgentListComponent.name, 'search', this.form.value);
    }

    get canAddAgent() {
        return this.user && this.authenticationService.hasPermission('CREATE_AGENT');
    }

    private loadSearchData() {
        this.pageManager.getData(AgentListComponent.name, 'search');
        let searchData = this.pageManager.getData(AgentListComponent.name, 'search');
        if (searchData.createdAfter) {
            searchData.createdAfter = moment(searchData.createdAfter).format('YYYY-MM-DD');
        }
        if (searchData.createdBefore) {
            searchData.createdBefore = moment(searchData.createdBefore).format('YYYY-MM-DD');
        }
        this.form.patchValue(searchData);
    }

    now() {
        return new Date();
    }

    getMinDate() {
        return this.minDate
    }

    getStatusStyle(approvalStatus: StatusEnum) {
        switch (approvalStatus) {
            case StatusEnum.ACTIVE:
                return 'pFinished';
            case StatusEnum.INACTIVE:
                return 'rejected';
            default:
                return '';
        }
    }
}
