<div class=" m-0">
    <div class="modal-header">
        <div class="container-fluid">
            <div class="d-flex align-items-center">
                <div class="pull-left flex-fill">
                    <h6 class="modal-title" id="modal-title">Edit App User Details</h6>
                </div>
                <button (click)="onCancel()" class="close pull-right" type="button">
                    <span aria-hidden="true" class="fa fa-times modal-title"></span>
                </button>
            </div>

        </div>

    </div>
    <div class="modal-body">
        @if (!loading && appUser) {
        <div class="container-fluid">
            <div class="filter-bg ">
                <form class="" (submit)="onUpdate()" [formGroup]="form" id="form">
                    <div class="custom-card">
                        <div class="row g-3">
                            <div class="col-md-6 form-group">
                                <label  class="required">Phone Number</label>
                                <input class="form-control" [formControlName]="'phoneNumber'" required id="phoneNumber"
                                       placeholder="07012345679" readonly [maxLength]="30"/>
                                <error-message [form]="form" [controlName]="'phoneNumber'"/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="required">First Name</label>
                                <input [formControlName]="'firstName'" class="form-control" required="" id="firstName"
                                       placeholder="First Name" [readOnly]="true" [maxLength]="30"/>
                                <error-message [form]="form" [controlName]="'firstName'"/>

                            </div>
                            <div class="col-md-6 form-group">
                                <label class="required">Last Name</label>
                                <input [formControlName]="'lastName'" class="form-control" required id="lastName"
                                       placeholder="Last Name" [readOnly]="true" [maxLength]="30"/>
                                <error-message [form]="form" [controlName]="'lastName'"/>

                            </div>
                            <div class="col-md-6 form-group">
                                <label class="required">Username</label>
                                <input [formControlName]="'username'" class="form-control" required id="username"
                                       placeholder="Username" [readOnly]="true" [maxLength]="30"/>
                                <error-message [form]="form" [controlName]="'username'"/>
                            </div>
                            <div class="col-md-12 form-group">
                                <hr/>
                            </div>
                            <div class="d-flex flex-column flex-md-row align-items-md-center">
                                @if (canSelectScheme) {
                                    <div class="flex-fill form-group mr-1">
                                        <label class="required">Scheme</label>
                                        <select class="form-control" [formControlName]="'organizationId'" id="organizationId">
                                            <option [value]="''">-- Select Scheme --</option>
                                            @for (org of organizations; track org) {
                                             <option [value]="org.id">{{ org.name | titlecase }}</option>
                                            }
                                        </select>
                                        <error-message [form]="form" [controlName]="'organizationId'"/>

                                    </div>
                                }

                                @if (canSelectAgent) {
                                    <div class="flex-fill form-group mr-1">
                                        <label class="required">Agent</label>
                                        <select class="form-control" [formControlName]="'agentId'" id="agentId">
                                            @if (fetchingAgents) {
                                                <option [value]="''">Fetching Agents</option>
                                            }
                                            @if (!fetchingAgents && !organizationId) {
                                                <option [value]="''">-- Select a Scheme --</option>
                                            }
                                            @if (!fetchingAgents && agents?.length == 0) {
                                                <option [value]="''">No agents found</option>
                                            }
                                            @if (!fetchingAgents && agents?.length) {
                                                <option *ngIf="agents?.length || 0 > 0" [value]="''">-- Select an Agent --</option>
                                                @for (p of agents; track p) {
                                                    <option [value]="p.id">{{ p.name }}</option>
                                                }
                                            }
                                        </select>
                                        <error-message [form]="form" [controlName]="'agentId'"/>

                                    </div>
                                }
                                <div class="flex-fill form-group mr-1">
                                    <label class="required">Role</label>
                                    <select class="form-control" [formControlName]="'role'" id="role">
                                        <option [value]="''">-- Select Role --</option>
                                        <option [value]="'SELLER'">Seller</option>
                                        <option [value]="'ENFORCER'">Enforcer</option>
                                    </select>
                                    <error-message [form]="form" [controlName]="'role'"/>

                                </div>
                                <div class="text-right">
                                    <button class="btn btn-outline-info" type="button" (click)="addRole()"><i class="fa fa-add"></i>Add</button>
                                </div>
                            </div>
                            <div class="col-md-12 form-group table-responsive">
                                <table class="table table-hover table-bordered table-sm">
                                    <thead>
                                    <tr class="">
                                        <th scope="col">#</th>
                                        @if (canSelectScheme) {
                                            <th scope="col">Scheme</th>
                                        }
                                        @if (canSelectAgent) {
                                            <th scope="col">Agent</th>
                                        }
                                        <th scope="col">Role</th>
                                        <th scope="col" style="max-width: 120px">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    @if (selectedRoles.length < 1) {
                                        <tr>
                                            <td colspan="8" class="my-5 text-center">
                                                NO DATA
                                            </td>
                                        </tr>
                                    }

                                    @for (d of selectedRoles; track d; let i = $index) {
                                        <tr>
                                            <th scope="row">{{ i + 1 }}</th>
                                            @if (canSelectScheme) {
                                                <td>{{ d.organizationName }}</td>
                                            }
                                            @if (canSelectAgent) {
                                                <td>{{ d.agentName }}</td>
                                            }
                                            <td>{{ d.role }}</td>
                                            <td>
                                                <button (click)="removeRole(i)" type="button" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i> Remove</button>
                                            </td>
                                        </tr>
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <hr class="mx-0 mx-auto w-100"/>
            <div class="px-md-3">
                <div class="row">
                    <div class="col-md-2">
                        Status:
                    </div>
                    <div class="col-md-10">
            <span class="font-weight-bold"
                  [ngClass]="{'text-success':appUser.status=='ACTIVE','text-danger':appUser.status=='INACTIVE'}">{{appUser.status}}</span>

                    </div>
                    <div class="col-md-2">
                        Username:
                    </div>
                    <div class="col-md-10">
                        {{appUser.username}}
                    </div>
                    <div class="col-md-2">
                        Date Created:
                    </div>
                    <div class="col-md-10">
                        {{appUser.createdAt | date }}
                    </div>
                </div>
            </div>
            <hr class="mx-0 mx-auto w-100"/>
        </div>
        }
    </div>

    @if (!loading && appUser) {
        <div class="modal-footer">
            <div class="container-fluid text-end">
                <button (click)="onCancel()" class="btn btn-danger mr-2" type="button">
                    <i class="fa fa-times mr-1 fa-spinner "></i
                    > Cancel
                </button>
                <button form="form" class="btn btn-success" type="submit">
                    <i class="fa" [ngClass]="{'fa-spinner fa-spin':loading}"></i>
                    Update
                </button>
            </div>
        </div>
    }

    @if (loading) {
        <div class="p-5" >
            <span>loading user</span>
        </div>
    }

    @if (!fetchingAppUser && !appUser) {
        <div class="p-5" >
            <bs-alert [type]="'danger'" class="text-center">Could not fetch user</bs-alert>
        </div>
    }
</div>
