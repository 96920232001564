import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpErrorResponse} from "@angular/common/http";
import {
  AgentControllerService,
  AgentPojo, DropDownListControllerService,
  EditAgentDto,
  OrganizationWithMembershipStatus,
  TicketTypeDto,
  Zone
} from "../../../../../sdk/tickets-sdk";
import {User} from "../../../models/user/user.model";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {AuthenticationService} from "../../../services/authentication.service";
import {Location} from "@angular/common";

@Component({
  selector: 'app-edit-agent-modal',
  standalone: true,
  imports: [],
  templateUrl: './edit-agent-modal.component.html',
  styleUrl: './edit-agent-modal.component.scss'
})
export class EditAgentModalComponent implements OnInit {

  @Input() agentId: number;
  onConfirmClicked = new EventEmitter();

  form!: FormGroup;
  zones: Array<Zone> = [];
  fetchingZone: boolean;
  fetchingTickets: boolean;
  organizations: Array<OrganizationWithMembershipStatus>;
  organizationId: number;
  agentTicketsConfig: Array<TicketTypeDto>;
  isPortalAdmin: boolean;
  user: User;
  loading: boolean;
  agent: AgentPojo;

  constructor(
      public modalRef: BsModalRef,
      private fb: FormBuilder,
      private agentControllerService: AgentControllerService,
      private toastrService: ToastrService,
      private dropDownListControllerService: DropDownListControllerService,
      private authenticationService: AuthenticationService,
      private location: Location
) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      mobileNumber: [''],
      email: [''],
      zoneCode: ['', [Validators.required]],
      organizationId: ['', Validators.required]
    });
    this.authenticationService.getAccount().subscribe(account => {
      if (account.accountType == 'ADMIN') {
        this.isPortalAdmin = true;
      } else {
        this.isPortalAdmin = false;
        this.organizationId = account.organization.id
        this.form.controls.organizationId.disable();
      }
    })

    this.form.controls.organizationId.valueChanges.subscribe( v => {
      if (v) {
        this.organizationId = v;
        this.fetchAgentTickets(v);
        this.fetchZones();
      } else {
        this.form.get('zoneCode').setValue('', {emitEvent: false})
        this.agentTicketsConfig = [];
      }
    })

    this.authenticationService.getUser().subscribe(u => {

      this.user = u;
    })

    this.fetchAgent();

  }

  fetchAgent() {
    this.agentControllerService.getAgent({id: this.agentId}).subscribe( v => {
      this.agent = v;
      this.organizations = this.agent.organizations;
      this.organizationId = this.agent.organizations[0].id;

      this.fetchAgentTickets(this.agent.organizations[0].id);
      this.fetchZones()
      this.prefillForm();
    }, (error) => {
      console.log(error);
      this.toastrService.error((error.error.message)
          ? error.error.message : 'Error fetching agent');
      this.location.back();
    });
  }

  prefillForm() {
    this.form.patchValue({email: this.agent.email, mobileNumber: this.agent.phoneNumber,
      firstName: this.agent.firstName, lastName: this.agent.lastName,
      zoneCode: this.agent.organizations[0].zone.code,
      organizationId: this.agent.organizations[0].id}, {emitEvent: false});
  }

  fetchAgentTickets(orgId: number) {
    this.fetchingTickets = true;
    this.agentControllerService.getAgentTicketsByOrganization({id: this.agentId , orgId})
        .subscribe( v => {
          this.fetchingTickets = false;
          this.agentTicketsConfig = v;
        }, (error: HttpErrorResponse) => {
          this.fetchingTickets = false;
        })
  }

  fetchZones() {
    this.fetchingZone = true;
    this.dropDownListControllerService.getZones({orgId: this.organizationId}).subscribe( v => {
      this.fetchingZone = false;
      this.zones = v.results;
    }, (error: HttpErrorResponse) => {
      this.fetchingZone = false;
    })
  }

  edit() {
    if (this.form.valid) {
      this.loading = true;
      const payload: EditAgentDto = {
        firstName: this.form.controls.firstName.value,
        lastName: this.form.controls.lastName.value,
        ticketTypes: this.agentTicketsConfig,
        zoneCode: this.form.controls.zoneCode.value,
        organizationId: this.organizationId
      }
      if (this.isPortalAdmin) {
        payload.organizationId = this.organizationId;
      }
      this.agentControllerService.editAgent({id: this.agent.id, editAgentDto: payload}).subscribe( res => {
        this.loading = false;
        this.toastrService.success('Agent added successfully!')
        this.onConfirmClicked.emit();
        this.modalRef.hide();
      }, (error: HttpErrorResponse) => {
        this.loading = false;
        this.toastrService.error((error.error && typeof error.error == 'object' && error.message)
            ? error.error.message : 'Error creating agent')
      });
    }
  }

  close() {
    this.modalRef.hide();
  }

}
