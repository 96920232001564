/**
 * Tickets API
 *
 * Contact: iumaar@icloud.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentInvoice } from './payment-invoice';
import { PortalUser } from './portal-user';


export interface PaymentTransaction { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    paymentProviderReference?: string;
    bwPaymentReference?: string;
    amount?: number;
    paymentDate?: string;
    payerName?: string;
    description?: string;
    transactionType?: PaymentTransaction.TransactionTypeEnum;
    paymentReference?: string;
    payerEmail?: string;
    payerPhone?: string;
    paymentProvider?: PaymentTransaction.PaymentProviderEnum;
    transactionStatus?: PaymentTransaction.TransactionStatusEnum;
    notificationId?: string;
    externalReceiptNumber?: string;
    paymentMethod?: PaymentTransaction.PaymentMethodEnum;
    endsystemRecordId?: number;
    paymentInvoice?: PaymentInvoice;
}
export namespace PaymentTransaction {
    export type TransactionTypeEnum = 'PAYMENT' | 'REVERSAL';
    export const TransactionTypeEnum = {
        PAYMENT: 'PAYMENT' as TransactionTypeEnum,
        REVERSAL: 'REVERSAL' as TransactionTypeEnum
    };
    export type PaymentProviderEnum = 'FLUTTERWAVE' | 'SQUAD' | 'PAYSTACK' | 'INTERSWITCH';
    export const PaymentProviderEnum = {
        FLUTTERWAVE: 'FLUTTERWAVE' as PaymentProviderEnum,
        SQUAD: 'SQUAD' as PaymentProviderEnum,
        PAYSTACK: 'PAYSTACK' as PaymentProviderEnum,
        INTERSWITCH: 'INTERSWITCH' as PaymentProviderEnum
    };
    export type TransactionStatusEnum = 'ACTIVE' | 'REVERSED' | 'DEACTIVATED';
    export const TransactionStatusEnum = {
        ACTIVE: 'ACTIVE' as TransactionStatusEnum,
        REVERSED: 'REVERSED' as TransactionStatusEnum,
        DEACTIVATED: 'DEACTIVATED' as TransactionStatusEnum
    };
    export type PaymentMethodEnum = 'CARD' | 'BOOK_ON_HOLD' | 'WEB_PAY' | 'POS_TERMINAL' | 'DIRECT_DEPOSIT' | 'BW_CASH_POS';
    export const PaymentMethodEnum = {
        CARD: 'CARD' as PaymentMethodEnum,
        BOOK_ON_HOLD: 'BOOK_ON_HOLD' as PaymentMethodEnum,
        WEB_PAY: 'WEB_PAY' as PaymentMethodEnum,
        POS_TERMINAL: 'POS_TERMINAL' as PaymentMethodEnum,
        DIRECT_DEPOSIT: 'DIRECT_DEPOSIT' as PaymentMethodEnum,
        BW_CASH_POS: 'BW_CASH_POS' as PaymentMethodEnum
    };
}


