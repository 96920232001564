<!-- Breadcrumb -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-between">
    <h5 class="mb-0">
        Agents
    </h5>
    <ol class="breadcrumb list-unstyled mt-0 mb-0 p-0">
        <li class="breadcrumb-item d-inline-block position-relative">
            <a routerLink="/dashboard" class="d-inline-block position-relative">
                <i class="material-symbols-outlined">
                    home
                </i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            Agents
        </li>
        <li class="breadcrumb-item d-inline-block position-relative">
            Agents List
        </li>
    </ol>
</div>

<div
        class="trezo-card table-style-two mb-25 border-radius d-block bg-white border-0 shadow-none"
        [class.component-rtl-enabled]="themeService.isRTLEnabled()"
>
    <div class="trezo-card-header d-sm-flex align-items-center justify-content-end">
        <div class="trezo-card-subtitle">
            <button class="add-btn d-inline-block transition fw-medium border-radius" type="button" (click)="createAgent()">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        add
                    </i>
                    Add New Agent
                </span>
            </button>
        </div>
    </div>
    <div class="trezo-card-header d-sm-flex align-items-center justify-content-between mt-4">
        <div class="trezo-card-title">
            <form [formGroup]="form" class="search-box position-relative">
                <label class="text-black">
                    <i class="material-symbols-outlined">
                        search
                    </i>
                </label>
                <input type="text" class="input-search d-block w-100 border-radius" placeholder="Search task here....." [formControlName]="'organizationName'">
            </form>
        </div>
        <div class="trezo-card-subtitle">
            <button class="add-btn btn-primary d-inline-block transition fw-medium border-radius" type="button" (click)="submit()">
                <span class="d-inline-block position-relative">
                    <i class="material-symbols-outlined">
                        search
                    </i>
                    Search
                </span>
            </button>
        </div>
    </div>
    <div class="trezo-card-content">
        <div class="basic-table table-responsive">
            <table class="table mb-0">
                <thead>
                <tr>
                    <th scope="col" class="fw-medium">
                        S/N
                    </th>
                    <th scope="col" class="fw-medium">
                        Agent Name
                    </th>
                    <th scope="col" class="fw-medium">
                        Agent ID
                    </th>
                    <th scope="col" class="fw-medium">
                        Phone Number
                    </th>
                    <th scope="col" class="fw-medium">
                        Email
                    </th>
                    <th scope="col" class="fw-medium">
                        Scheme Name(s)
                    </th>
                    <th scope="col" class="fw-medium">
                        Status
                    </th>
                    <th scope="col" class="fw-medium">
                        Action
                    </th>
                </tr>
                </thead>
                <tbody>
                    @for (d of searchManager.list; track d; let i = $index) {
                        <tr>
                            <td class="text-body">{{ i + 1 }}</td>
                            <td>
                                {{ d.name || '-' }}
                            </td>
                            <td>{{ d.code }}</td>
                            <td>{{ d.phoneNumber || '-' }}</td>
                            <td>{{ d.email || '-' }}</td>
                            <td>{{ getOrganizationNames(d?.organizations) }}</td>
                            <td>
                                <span class="trezo-badge" [ngClass]="getStatusStyle(d.status)">
                                    {{ d.status }}
                                </span>
                            </td>
                            <td>
                                <div class="action-buttons d-flex align-items-center">
                                    <button type="button" class="p-0 bg-transparent d-inline-block lh-1 border-0"
                                            [routerLink]="'/dashboard/organizations/detail/' + d.id">
                                        <i class="material-symbols-outlined"> visibility </i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
</div>
